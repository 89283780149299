import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { contentfulSite } = useStaticQuery(
    graphql`
      {
        contentfulSite {
          url
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    `,
  );

  const metaDescription =
    description || contentfulSite.metaDescription.metaDescription;
  const page = title ? ` | ${title}` : '';
  const metaTitle = `${contentfulSite.metaTitle}${page}`;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: process.env.GATSBY_SITE_SARA ? `en` : `it`,
  meta: [],
  description: ``,
  title: undefined,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
