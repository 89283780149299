import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Collapse, { Panel } from 'rc-collapse';

import style from './menu.module.css';
import './menu.css';

const Menu = ({ pathname }) => {
  const { contentfulNavigation } = useStaticQuery(graphql`
    {
      contentfulNavigation {
        albums {
          ... on ContentfulAlbums {
            id
            name
            albums {
              id
              name
              slug
            }
            internal {
              type
            }
          }
        }
      }
    }
  `);

  let activeKey;

  if (pathname) {
    const activeAlbum = contentfulNavigation.albums.find(
      ({ albums }) =>
        albums && albums.some(({ slug }) => slug === pathname.split('/')[1]),
    );
    activeKey = activeAlbum && activeAlbum.id;
  }

  return (
    <nav className={style.nav}>
      {contentfulNavigation.albums.map(node => {
        if (node.internal.type === 'ContentfulAlbums') {
          return (
            <Collapse accordion defaultActiveKey={activeKey} key={node.id}>
              <Panel key={node.id} header={node.name} showArrow={false}>
                {node.albums.map(album => (
                  <Link
                    key={album.id}
                    className={style.link}
                    to={`/${album.slug}`}
                  >
                    {album.name}
                  </Link>
                ))}
              </Panel>
            </Collapse>
          );
        }
        return (
          <Link
            key={node.id}
            className={style.link}
            activeClassName={style.activeLink}
            to={`/${node.slug}`}
          >
            {node.name}
          </Link>
        );
      })}
      <Link
        className={style.link}
        activeClassName={style.activeLink}
        to="/contact"
      >
        Contact
      </Link>
    </nav>
  );
};

Menu.propTypes = {
  pathname: PropTypes.string,
};

Menu.defaultProps = {
  pathname: undefined,
};

export default Menu;
